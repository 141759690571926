import { useSelector } from "react-redux";
import { useGetVendorsQuery } from "../app/server/cashbackApi";

import ProductCard from "./ProductCard";
import ProductCardLoading from "./loading/ProductCardLoading";
import EmptyBox from "./EmptyBox";
import CustomPagination from "./CustomPagination";
import { useRef } from "react";

const VendorsContint = ({ withSearch }) => {
  const sectionRef = useRef(null);

  const { venue, category, page, search } = useSelector((state) => state.app);

  const { data: vendors, isSuccess } = useGetVendorsQuery({
    venue: venue.id,
    category,
    page,
    search: withSearch ? search : null,
  });

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="offer-section section-style" ref={sectionRef}>
      <div className="container">
        <div className="main-title">
          <h2 className="title">
            {search ? (
              <>
                نتائج البحث عن <span>{search}</span>
              </>
            ) : (
              "كل المزودين"
            )}
          </h2>
        </div>

        {isSuccess && vendors?.data?.length < 1 ? (
          <EmptyBox />
        ) : !isSuccess ? (
          <div className="vendors-contint">
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
            <ProductCardLoading />
          </div>
        ) : (
          <div className="vendors-contint" key={venue}>
            {vendors?.data?.map((item, idx) => (
              <ProductCard item={item} key={idx} />
            ))}
          </div>
        )}

        <CustomPagination meta={vendors?.meta} callback={scrollToSection} />
      </div>
    </section>
  );
};

export default VendorsContint;
