import { SwiperSlide, Swiper } from "swiper/react";
import {FreeMode} from 'swiper/modules';
import ProductCard from "./ProductCard";
import { useGetVendorsQuery } from "../app/server/cashbackApi";
import { useSelector } from "react-redux";
import ProductCardLoading from "./loading/ProductCardLoading";
import EmptyBox from "./EmptyBox";

const BestOfferSlider = () => {
  const featured = "yes";
  const { venue, category } = useSelector((state) => state.app);

  const { data: offers, isSuccess } = useGetVendorsQuery({
    featured,
    venue: venue.id,
    category,
  });

  return isSuccess && offers?.data?.length === 0 ? (
    <EmptyBox />
  ) : (
    <Swiper
      modules={[FreeMode]}
      className="head-slider"
      spaceBetween={16}
      slidesPerView={4}
      grabCursor={true}
      freeMode={window.innerWidth < 768 ? true : false}
      speed={window.innerWidth < 768 ? 1000 : 300}
      breakpoints={{
        0: {
          slidesPerView: 1.5,
          spaceBetween : 12,
        },
        576: {
          slidesPerView: 2.2,
          slidesPerGroup : 2
        },
        868: {
          slidesPerView: 3.2,
          slidesPerGroup : 3
        },
        992: {
          slidesPerView: 3.25,
          slidesPerGroup : 3
        },
        1200: {
          slidesPerView: 4.25,
          slidesPerGroup : 4
          
        },
      }}
      key={venue}
    >
      {isSuccess ? (
        offers?.data?.map((item) => (
          <SwiperSlide key={item.id}>
            <ProductCard item={item} />
          </SwiperSlide>
        ))
      ) : (
        <>
          <SwiperSlide>
            <ProductCardLoading />
          </SwiperSlide>

          <SwiperSlide>
            <ProductCardLoading />
          </SwiperSlide>

          <SwiperSlide>
            <ProductCardLoading />
          </SwiperSlide>

          <SwiperSlide>
            <ProductCardLoading />
          </SwiperSlide>

          <SwiperSlide>
            <ProductCardLoading />
          </SwiperSlide>

        </>
      )}
    </Swiper>
  );
};

export default BestOfferSlider;
