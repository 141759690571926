import DefaultImg from "./DefaultImg";

const SubcategoryCard = ({ item }) => {
  return (
    <div className="subcategory-card" >
      <div className="subcategory-img">
        <DefaultImg alt={item.name} src={item.image} />
      </div>

      <div className="subcategory-info">
        <h3 className="subcategory-name">{item.name}</h3>

        {item.description && (
          <p className="subcategory-desc">{item.description}</p>
        )}

        <p className="subcategory-price">{item.price} د.ع</p>
      </div>
    </div>
  );
};

export default SubcategoryCard;
