import { useDispatch } from "react-redux";
import { setPage } from "../app/reducer/appSlice";

const CustomPagination = ({ meta, callback }) => {
  const dispatch = useDispatch();

  const handlePageChange = (page) => {
    if (callback) {
      callback();
      setTimeout(() => {
        dispatch(setPage(page));
      }, 500);
    } else {
      dispatch(setPage(page));
    }
  };

  if (!meta || meta.last_page === 1) return null;

  return (
    <div className="pagination">
      <button
        disabled={meta.current_page === 1}
        className={`pagination-btn`}
        onClick={() => handlePageChange(meta.current_page - 1)}
      >
        السابق
      </button>

      <p className="pagination-info">
        <span className="current">{meta.current_page}</span>

        <span className="last">/ {meta.last_page}</span>
      </p>

      <button
        disabled={meta.current_page === meta.last_page || meta.last_page === 1}
        className={`pagination-btn `}
        onClick={() => handlePageChange(meta.current_page + 1)}
      >
        التالي
      </button>
    </div>
  );
};

export default CustomPagination;
