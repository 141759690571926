import {
  CategoryTabs,
  BannerSwiper,
  BestOfferSlider,
  VendorsContint,
} from "../components";
import { useSelector } from "react-redux";

const Home = () => {
  const { venue, category, page } = useSelector((state) => state.app);

  return (
    <>
      <header className="header-section section-style">
        <div className="container">
          <BannerSwiper />
        </div>
      </header>

      <section className="category-tab-section section-style">
        <div className="container">
          <div className="main-title">
            <h2 className="title">تصفح حسب الفئة</h2>
          </div>

          <CategoryTabs />
        </div>
      </section>

      <section className="offer-section section-style">
        <div className="container">
          <div className="main-title">
            <h2 className="title">افضل المزودين</h2>
          </div>

          <BestOfferSlider key={venue.id + category} />
        </div>
      </section>

      <VendorsContint key={venue.id + category + page} />
    </>
  );
};

export default Home;
