import { Search } from "lucide-react";
import SelectOptions from "./SelectOptions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setSearch } from "../app/reducer/appSlice";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [searchTxt, setSearchTxt] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    setSearchTxt(e.target.value);
    if (e.target.value === "") {
      dispatch(setSearch(""));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTxt.trim() !== "") {
      dispatch(setSearch(searchTxt));
      navigation("/search");
      setSearchTxt("");
    }
  };

  return (
    <nav className="nav-bar">
      <div className="container">
        <div className="nav-contint">
          <Link to={"/"} className="nav-logo">
            <img src="/logo.png" loading="lazy" alt="logo" />
          </Link>

          <div className="nav-search">
            <form onSubmit={handleSubmit}>
              <div className="input-container form-group">
                <input
                  type="text"
                  placeholder="ابحث عن.."
                  onChange={handleSearch}
                  value={searchTxt}
                />

                <button type="submit" className="input-icon">
                  <Search />
                </button>
              </div>
            </form>
          </div>

          <SelectOptions />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
