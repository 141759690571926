import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVenue } from "../app/reducer/appSlice";
import { ChevronDown } from "lucide-react";
import { useGetAllCitiesQuery } from "../app/server/cashbackApi";

const SelectOptions = () => {
  const dispatch = useDispatch();

  const [openSelect, setOpenSelect] = useState(false);
  const { venue } = useSelector((state) => state.app);

  const handleVenue = (id) => {
    dispatch(setVenue(id));
    setOpenSelect(false);
  };

  const { data: cities } = useGetAllCitiesQuery();

  return (
    <div className="custom-dropdown">
      <button
        onClick={() => setOpenSelect(!openSelect)}
        className={`dropdown-active ${openSelect && "open"}`}
      >
        <span>{venue.name}</span>

        <ChevronDown className="arrow-icon" />
      </button>

      <div
        className={`custom-dropdown-list noScroll-bar ${
          openSelect && "active"
        }`}
      >
        <button
          className="dropdown-list-item"
          onClick={() =>
            handleVenue({
              id: 0,
              name: "كل المناطق",
            })
          }
        >
          كل المناطق
        </button>

        {cities?.data?.map((item, idx) => (
          <button
            className="dropdown-list-item"
            onClick={() => handleVenue(item)}
            key={idx}
          >
            {item.name}
          </button>
        ))}
      </div>

      <div
        onClick={() => setOpenSelect(false)}
        className={`custom-overlay ${openSelect && "open"}`}
      ></div>
    </div>
  );
};

export default SelectOptions;
