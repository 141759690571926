import DefaultImg from "./DefaultImg";

const BannerCard = ({ item }) => {
  return (
    <a
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
      className="banner-card"
    >
      <div className="banner-card-img">
        <DefaultImg
          src={item.image}
          alt={item.name || "banner"}
        />
      </div>

      <div className="banner-card-content">
        <h3 className="banner-card-title">{item.name}</h3>

        {/* <div className="banner-card-desc">
          <p>
            نص تجريبي للعرض الخاص بالمنتج والخصم المقدم عليه والتفاصيل الخاصة به
            والمزيد من التفاصيل الخاصة بالمنتج والخصم المقدم عليه والتفاصيل
            الخاصة به والمزيد من التفاصيل الخاصة بالمنتج والخصم المقدم عليه
            والتفاصيل الخاصة به والمزيد من التفاصيل
          </p>
        </div>

        <div className="banner-card-btn">
          <button className="custom-btn">مشاهدة العرض</button>
        </div> */}
      </div>
    </a>
  );
};

export default BannerCard;
