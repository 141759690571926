const ProductCardLoading = ({ withPage }) => {
  return (
    <div className="product-card" style={{ pointerEvents: "none" }}>
      <div className="card-img skeleton"></div>

      <div className="card-body">
        <div className="card-body-content">
          <div className="card-title skeleton skeleton-text sm"></div>

          <div className="card-flex skeleton skeleton-text xsm"></div>

          <div className="card-flex skeleton skeleton-text xs"></div>

          <div className="skeleton skeleton-text xsm"></div>
        </div>

        {withPage && (
          <div className="card-page-desc">
            <div className="card-title skeleton skeleton-text sm"></div>
            
            <div className="card-flex skeleton skeleton-text xsm"></div>
            <div className="card-flex skeleton skeleton-text xs"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCardLoading;
