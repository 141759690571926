import { Link, useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useGetProductQuery } from "../app/server/cashbackApi";
import { useEffect, useState } from "react";
import ProductCardLoading from "../components/loading/ProductCardLoading";
import SubcategoryCardLoading from "../components/loading/SubcategoryCardLoading";
import SubcategoryCard from "../components/SubcategoryCard";
import EmptyBox from "../components/EmptyBox";

const ProductPage = () => {
  const params = useParams();
  const [category, setCategory] = useState(0);
  const [categoryTab, setCategoryTab] = useState([]);
  const [updateUI, setUpdateUI] = useState(true);

  const handleTabClick = (id) => {
    setCategory(id);
    setUpdateUI(false);
  };

  const { data: product, isSuccess } = useGetProductQuery({
    id: params.id,
    subcategory: category,
  });

  useEffect(() => {
    if (product && category === 0) {
      setCategoryTab(product?.data);
    }

    setTimeout(() => {
      setUpdateUI(true);
    }, 500);
  }, [product]);

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <ul className="breadcrumb-nav">
            <li className="breadcrumb-item">
              <Link to="/">الرئيسية</Link>
            </li>

            <li className="breadcrumb-item active">
              {product?.data[0]?.vendor?.name
                ? product?.data[0]?.vendor?.name
                : "المنتجات"}
            </li>
          </ul>
        </div>
      </section>

      <section className="product-page section-style about-vendor">
        <div className="container">
          {isSuccess && product?.data.length < 1 ? (
            <EmptyBox title="لا توجد معلومات عن هذا المزود" />
          ) : isSuccess ? (
            <ProductCard item={product?.data[0]?.vendor} withPage />
          ) : (
            <ProductCardLoading withPage />
          )}
        </div>
      </section>

      <section className="product-page section-style vendor-products">
        <div className="container">
          <div className="main-title">
            <h2 className="title">قائمة المنتجات</h2>
          </div>

          {categoryTab.length > 0 && (
            <div className="category-tab-container product-page-tab noScroll-bar">
              <button
                onClick={() => handleTabClick(0)}
                className={`category-tab-btn ${category === 0 && "active"}`}
              >
                <span className="category-tab-name">الكل</span>
              </button>

              {categoryTab?.map((item, idx) => (
                <button
                  onClick={() => handleTabClick(item?.subcategory?.id)}
                  key={idx}
                  className={`category-tab-btn ${
                    category === item?.subcategory?.id && "active"
                  }`}
                >
                  <span className="category-tab-name">
                    {item?.subcategory?.name}
                  </span>
                </button>
              ))}
            </div>
          )}

          {isSuccess && product?.data.length < 1 ? (
            <EmptyBox />
          ) : (
            <div className="subcategory-content">
              {isSuccess && updateUI ? (
                product?.data?.map((item, idx) => (
                  <SubcategoryCard item={item} key={idx} />
                ))
              ) : (
                <>
                  <SubcategoryCardLoading />
                  <SubcategoryCardLoading />
                  <SubcategoryCardLoading />
                  <SubcategoryCardLoading />
                </>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProductPage;
