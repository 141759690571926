import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  venue: {
    id: 0,
    name: "كل المناطق",
  },
  category: 0,
  search: "",
  page: 1,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setVenue: (state, action) => {
      state.venue = action.payload;
    },

    setCategory: (state, action) => {
      state.category = action.payload;
    },

    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setReset: (state) => {
      // state.venue = initialState.venue;
      state.category = initialState.category;
      state.page = initialState.page;
    },
  },
});

export const { setVenue, setCategory, setSearch, setPage, setReset } =
  appSlice.actions;
export default appSlice.reducer;
