const DefaultImg = ({ src, alt, className = "", ...props }) => {
  return (
    <img
      src={"https://admin.cashback-iq.com/storage/" + src}
      alt={alt}
      loading={"lazy"}
      className={className}
      {...props}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "/broken-image.png";
        e.target.className = `${className} broken-image`;
      }}
    />
  );
};

export default DefaultImg;
