const EmptyBox = ({
  title = "لا يوجد منتجات",
}) => {
  return (
    <div className="empty-box">
      <img src="/no-result.jpg" alt="empty box" />

      <h3 className="title">{title}</h3>
    </div>
  );
};

export default EmptyBox;
