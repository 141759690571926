import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import BannerCard from "./BannerCard";
import { useGetBannersQuery } from "../app/server/cashbackApi";
import BannerCardLoading from "./loading/BannerCardLoading";

const BannerSwiper = () => {
  const { data: banners, isSuccess } = useGetBannersQuery();

  return (
    <div>
      <Swiper
        className="head-slider"
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 5000,
        }}
      >
        {isSuccess ? (
          banners?.data?.map((item, idx) => (
            <SwiperSlide key={idx}>
              <BannerCard item={item} />
            </SwiperSlide>
          ))
        ) : (
          <>
            <SwiperSlide>
              <BannerCardLoading />
            </SwiperSlide>

            <SwiperSlide>
              <BannerCardLoading />
            </SwiperSlide>

            <SwiperSlide>
              <BannerCardLoading />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default BannerSwiper;
