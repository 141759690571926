import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appSlice from "./reducer/appSlice";
import { cashbackApi } from "./server/cashbackApi";

export const store = configureStore({
  reducer: {
    app: appSlice,
    cashbackApi: cashbackApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cashbackApi.middleware),
});
