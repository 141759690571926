const Footer = () => {
  return (
    <footer className="section-style footer-section">
      <div className="container">
        <div className="footer-content">
          <div className="footer-about">
            <div className="footer-logo">
              <img src="/logo.png" loading="lazy" alt="logo" />
            </div>
            <p>
            تطبيق "كاش باك"  هو الحل الاقتصادي الشامل الذي يتيح للمستخدمين الاستمتاع بخدمات متنوعة من مختلف القطاعات. يقدم التطبيق فرصًا رائعة لتوفير الأموال في مجالات مثل التسوق، والخدمات الصحية والجمال، والتجهيزات المنزلية، والترفيه، والعديد من القطاعات الأخرى. يُعتبر "كاش باك" رفيقًا موثوقًا يجمع بين تجارب متنوعة وخصومات جذابة، مما يوفر للمستخدمين فرصة استفادة قصوى من خدمات متعددة بتكلفة أقل.            </p>
          </div>
          <div className="contact-list">
            <div className="list-item">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
              </span>
              <p className="item-text"> العراق- بغداد- المنصور </p>
            </div>
            <div className="list-item">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M347.1 24.6c7.7-18.6 28-28.5 47.4-23.2l88 24C499.9 30.2 512 46 512 64c0 247.4-200.6 448-448 448c-18 0-33.8-12.1-38.6-29.5l-24-88c-5.3-19.4 4.6-39.7 23.2-47.4l96-40c16.3-6.8 35.2-2.1 46.3 11.6L207.3 368c70.4-33.3 127.4-90.3 160.7-160.7L318.7 167c-13.7-11.2-18.4-30-11.6-46.3l40-96z" />
                </svg>
              </span>
              <p className="item-text">
                <a href="tel:+9647510524614">
                  {" "}
                  <bdi>+9647510524614</bdi>{" "}
                </a>
              </p>
            </div>

            <div className="list-item">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                </svg>
              </span>
              <p className="item-text">
                <a href="mailto:www.cashback-iq.com">www.cashback-iq.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="copy-rights">كل الحقوق محفوظه © كاش باك العراق</div>
      </div>
    </footer>
  );
};

export default Footer;
