import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setReset } from "../app/reducer/appSlice";
import { useDispatch } from "react-redux";

const ScrollToTop = () => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setReset());
  }, [pathName]);

  return null;
};

export default ScrollToTop;
