import React from "react";
import { useSelector } from "react-redux";
import { CategoryTabs, VendorsContint } from "../components";
import { Link } from "react-router-dom";

const SearchPage = () => {
  const { venue, category, page, search } = useSelector((state) => state.app);

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <ul className="breadcrumb-nav">
            <li className="breadcrumb-item">
              <Link to="/">الرئيسية</Link>
            </li>

            <li className="breadcrumb-item active">البحث</li>
          </ul>
        </div>
      </section>

      <section className="category-tab-section section-style">
        <div className="container">
          <div className="main-title">
            <h2 className="title">تصفح حسب الفئة</h2>
          </div>

          <CategoryTabs />
        </div>
      </section>

      <VendorsContint
        withSearch
        key={venue.id + category + page + search}
      />
    </>
  );
};

export default SearchPage;
