import { Clock, MapPin } from "lucide-react";
import { Link } from "react-router-dom";
import DefaultImg from "./DefaultImg";

const ProductCard = ({ item, withPage }) => {
  const contentPage = (
    <>
      <div className="card-img">
        <DefaultImg src={item.image} alt={item.name} />
      </div>

      <div className="card-body">
        <div className="card-body-content">
          <h3 className="card-title">{item.name}</h3>

          <div className="card-flex">
            <span className="offer-num">خصم يصل الى {item.discount}%</span>

            {/* <p className="card-txt">قيمة العرض</p> */}
          </div>

          <div className="card-flex">
            <span className="offer-num">{~~item.max_discount}</span>

            <p className="card-txt">الحد الأدنى للشراء</p>
          </div>

          <div className="card-flex">
            <Clock className="offer-date-icon" />
            {item.end_at ? (
              <p className="card-txt">
                ينتهي بتاريخ {new Date(item.end_at).toLocaleDateString()}
              </p>
            ) : (
              <p className="card-txt">العرض مستمر</p>
            )}
          </div>

          <div className="card-flex">
            <MapPin className="offer-map-icon" />

            <p className="card-txt">{item?.venue?.name || "المنطقة"}</p>
          </div>
        </div>

        {withPage && (
          <div className="card-page-desc">
            <h3 className="card-title">وصف المطعم</h3>

            <p className="card-description">
              {item.description || "لا يوجد وصف لهذا المطعم"}
            </p>
          </div>
        )}
      </div>
    </>
  );
  
  return withPage ? (
    <div className="product-card">{contentPage}</div>
  ) : (
    <Link to={`/product/${item.id}`} className="product-card">
      {contentPage}
    </Link>
  );
};

export default ProductCard;
