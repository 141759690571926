import { ListChecksIcon } from "lucide-react";
import { useGetAllCategoriesQuery } from "../app/server/cashbackApi";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../app/reducer/appSlice";

const CategoryTabs = () => {
  const dispatch = useDispatch();

  const { category } = useSelector((state) => state.app);

  const { data: categories, isSuccess } = useGetAllCategoriesQuery();

  const handleTabClick = (id) => {
    dispatch(setCategory(id));
  };

  return (
    <div className="category-tab-container noScroll-bar">
      {isSuccess ? (
        <>
          <button
            onClick={() => handleTabClick(0)}
            className={`category-tab-btn ${category === 0 && "active"}`}
          >
            <ListChecksIcon />

            <span className="category-tab-name">الكل</span>
          </button>

          {categories?.data?.map((item) => (
            <button
              onClick={() => handleTabClick(item.id)}
              key={item.id}
              className={`category-tab-btn ${category === item.id && "active"}`}
            >
              <img
                className="category-tab-icon"
                alt={item.name}
                src={item.full_image}
              />

              <span className="category-tab-name">{item.name}</span>
            </button>
          ))}
        </>
      ) : (
        <>
          <div className={"skeleton tap"}></div>
          <div className={"skeleton tap"}></div>
          <div className={"skeleton tap"}></div>
          <div className={"skeleton tap"}></div>
        </>
      )}
    </div>
  );
};

export default CategoryTabs;
