const SubcategoryCardLoading = () => {
  return (
    <div className="subcategory-card">
      <div className="subcategory-img skeleton"></div>

      <div className="subcategory-info">
        <div className="subcategory-name skeleton skeleton-text sm"></div>

        <div className="subcategory-name skeleton skeleton skeleton-text xsm"></div>
        <div className="skeleton skeleton skeleton-text xs"></div>
      </div>
    </div>
  );
};

export default SubcategoryCardLoading;
