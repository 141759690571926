import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `https://admin.cashback-iq.com/api/v1`;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const cashbackApi = createApi({
  reducerPath: "cashbackApi",
  baseQuery: fetchBaseQuery({ baseUrl, headers }),
  tagTypes: ["Home"],
  endpoints: (builder) => ({
    getAllCities: builder.query({
      query: () => `/venues`,
      providesTags: ["Home"],
    }),

    getBanners: builder.query({
      query: () => `/banners`,
      providesTags: ["Home"],
    }),

    getAllCategories: builder.query({
      query: () => `/categories`,
      providesTags: ["Home"],
    }),

    getVendors: builder.query({
      query: (arg) => {
        const params = {};

        if (arg.venue) {
          params.venue = arg.venue;
        }

        if (arg.featured) {
          params.featured = "yes";
        }

        if (arg.category) {
          params.category = arg.category;
        }

        if (arg.search) {
          params.search = arg.search;
        }

        if (arg.page) {
          params.page = arg.page;
        }

        return {
          url: `/vendors?${new URLSearchParams(params).toString()}`,
        };
      },
    }),

    getProduct: builder.query({
      query: (arg) => {
        const params = {
          vendor: arg.id,
        };

        if (arg.subcategory) {
          params.subcategory = arg.subcategory;
        }

        return {
          url: `/products?${new URLSearchParams(params).toString()}`,
        };
      },
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetVendorsQuery,
  useGetAllCitiesQuery,
  useGetBannersQuery,
  useGetProductQuery,
} = cashbackApi;
